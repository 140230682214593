import './style.css';

import React, {useEffect, useState} from "react";
import {decodeBase64, fetchSessData, saveConfig, saveToken} from "../core/token";
import {fillConfig, GlobalContext} from "./GlobalContext";

import Header from "./Header";
import Footer from "./Footer";
import Left   from "./Left";

import Man from "./Man";
import Promo from "./Promo";
import Welcome from "./Welcome";

import {Layout} from 'antd';

function App() {
    const [config, setConfig] = useState({})
    const [curUserInfo, setCurUserInfo] = useState({state: false});
    const [path, setP] = useState( decodeURIComponent( document.location.pathname || "/" ) );
    const [app, setApp] = useState({})

    function setPath(
        p,
        obj = {},
        replace=false,
        // closer = ()=>{}
    ){

        if( typeof(p) === "object" ) {
            if ( ("preventDefault" in p) && ("currentTarget" in p) ) {
                if ("href" in p.currentTarget) {
                    p.preventDefault();
                    const a = p.currentTarget
                    if("host" in a){
                        p = a.href.split(a.host)[1]
                    }else {
                        p = a.href
                    }
                }else{
                    return false;
                }
            }
        }

        const url = p
            .replace(window.location.origin, "")
            .replace("/#!/", "/")

        if(path!==url) {
            console.log(path + " → " + url)
            setP(url)
        }
        const data = {path: url, obj: obj}

        const prevPath = ((window.history.state && window.history.state.path) ? window.history.state.path : "")
        if (url !== prevPath || replace) {
            if (replace) {
                window.history.replaceState(data, "", url)
            } else {
                window.history.pushState(data, "", url)
            }
        }

    }

    useEffect(() => {
        (()=>{
            // try {
                const srch = window.location.search || window.location.hash.split("?")[1];
                if(srch) {
                    (srch[0] === "?" ? srch.slice(1) : srch).split("&").forEach(it => {
                        const ca = it.split("=")
                        if (ca[1] && ca[0]) {
                            if(ca[0] === "apus") {
                                sessionStorage.setItem(ca[0], ca[1])
                                saveToken( JSON.parse( decodeBase64(ca[1]) ) )
                                setPath(document.location.pathname, {}, true)
                            }
                        }
                    })
                }
            // }catch(_){
            // }
        })()

        fillConfig(setConfig);

        window.addEventListener("popstate", (e)=>{
            // удалить все диалоги!
            Array.from(document.querySelectorAll("dialog")).forEach( dia => {
                dia.close(); dia.remove();
            })
            console.log("popState!", document.location.pathname, e.state)
            setPath( document.location.pathname, e.state||{} )
        })

        fetchSessData( setCurUserInfo );

        const sp = document.location.href.split("/#!")[1]
        if(sp) setPath( decodeURIComponent(sp), {},true )

    }, []);

    useEffect(() => {
        if(Object.keys(config).length) {
            // сохранить в браузере урл для обновления токена
            // так проще, нежели переписывать gFetch как кастомный хук
            // а если его не переписывать на кастомный хук, то в нём нельзя использовать useContext для доступа к конфигу
            // setJwtAccTokenUrl(config["JWT_ACCTOKEN_URL"]) // sessionStorage.setItem("CONFIG_JWT_ACCTOKEN_URL", config["JWT_ACCTOKEN_URL"])
            saveConfig(config)
        }
    }, [config])

    window["doLogoff"] = ()=>{
        saveToken({}) // сбросить-обнулить токены
        setCurUserInfo({state: false})
        setPath("/")
    }

    return (
      <GlobalContext.Provider value={{
          config: config, curUserInfo: curUserInfo,
          setPath: setPath, path: path,
          app: app, setApp: setApp,
      }}>
          <Layout>
              <Header/>
              <Layout id={"content"}>
                  <Left />
                  <Layout.Content id={"main"}>
                      {[
                          (( path === "" || path === "/" || path.indexOf("/src/")===0 )       ? <Welcome /> : <></>)
                          ,((new RegExp("^/mans/")).test(path)                                 ? <Man      /> : <></>)
                          ,((new RegExp("^/promo/")).test(path)                                ? <Promo    /> : <></>)
                      ]}
                  </Layout.Content>
              </Layout>
              <Footer />
          </Layout>
      </GlobalContext.Provider>
  );
}

export default App;
