import "./style.css";

import React, {useContext, useEffect, useState} from "react";
import {Button, Descriptions, FloatButton, Popconfirm, Popover, Skeleton, Space, Table, Tag, Tooltip} from "antd";

import {GlobalContext} from "../../GlobalContext";
import {
    fetchTemplates,
    showFormForUpdTemplate,
    showFormForDelTemplate,
    showFormForAddTemplate, showBuilder,
} from "./funcs";
import {
    DeleteOutlined, DeleteTwoTone,
    EditOutlined, ExperimentTwoTone,
    PlusOutlined,
} from "@ant-design/icons";
import {changeItem} from "../../../core/list";
import {getColumnSearchProps} from "../../../core/columnSearchProps";
import {allCats, woCat} from "../Categories";
import User from "../../User";

export default function Templates() {
    const {
        // config,
        // curUserInfo,
        // path, setPath,
        app, setApp,
    } = useContext(GlobalContext)

    const [templates, setTemplates] = useState([])
    const [data, setData] = useState([])
    const [showTemplate, setShowTemplate] = useState({})
    const [ready, setReady] = useState(false)

    useEffect(()=>{
        console.debug("новый app "+ ((app.storage && app.storage.code && (!(templates.length) || templates[0].src !== app.storage.code ))))
        if(app.storage && app.storage.code && (!(templates.length) || templates[0].src !== app.storage.code ) ){
            setReady(false)
            fetchTemplates(app.storage.code, (tmpls) => {
                setTemplates(tmpls)
            })
        }
    }, [app])

    useEffect(() => {
        if(templates) {
            if (!(app.template)) { // не загружены ещё шаблоны
                console.debug("шаблончики: " + templates.length, " категория: " + JSON.stringify(app.category))
                const tmpls = (templates || []).map(it => Object.assign(it, {key: it.code}))
                if (app.category && (app.category.key || app.category.name) && (app.category.key || app.category.name) !== allCats.key) {
                    if ((app.category.key || app.category.name) === woCat.key) {
                        console.debug("отбор без категории")
                        setData(tmpls.filter(it => (it.category || "").trim() === ""))
                    } else {
                        setData(tmpls.filter(it => (it.category === (app.category.key || app.category.name))))
                    }
                } else {
                    setData(tmpls)
                }
            } else { // загружены уже шаблоны
                if (app.template && app.template.code && !(showTemplate.code)) { // видимо надо отобразить билдер
                    setShowTemplate(((templates.find((t) => (t.code === app.template.code))) || Object.assign(app.template, {
                        src: (app.template.src || app.storage.code),
                        category: (app.template.category || (app.category && (app.category.key || app.category.name)) || allCats.key )
                    })) || {})
                }
            }
        }
    }, [templates, app /* тут меняется категория*/]);

    useEffect(() => {
        // console.log("ch showTemplate", ( (showTemplate && showTemplate.code) ), showTemplate)
        if(showTemplate && showTemplate.code) {
            // console.log("showTemplate", showTemplate)
            showBuilder( showTemplate,
                /* {
                    src: (showTemplate.src || app.template.src || app.storage.code),
                    category: (showTemplate.category || app.template.category || app.category.key || app.category.name),
                    code: (showTemplate.code || app.template.code)
                }, */
                () => {
                    // console.log("CLOSER")
                    setShowTemplate({})
                    delete app.template
                    const newApp = Object.assign({}, app, {template: null})
                    // console.log("newApp", newApp)
                    setApp(newApp)
                    // setPath(`/src/${src}/cat/${cat||allCats.key}/`)
                })
        }
    }, [showTemplate]);

    useEffect(()=>{
        setReady(true)
    }, [data])

    // const [searchText, setSearchText] = useState("");
    // const [searchedColumn, setSearchedColumn] = useState("");

    return (<>
        {
            !ready ? <Skeleton active loading />
                : <Table
                    rowKey="key"
                    dataSource={data}
                    pagination={{hideOnSinglePage: true}}
                    onRow={(record)=>{
                        return {
                            onDoubleClick: (e)=>{
                                e.preventDefault()
                                // setShowTemplate(record)
                                setApp( Object.assign({}, app, {template: record}))
                                /*
                                setPath(""
                                    +"/src/"+encodeURIComponent(record.src)
                                    +"/cat/"+encodeURIComponent(cat || record.category)
                                    +"/tmpl/"+encodeURIComponent(record.code)
                                )
                                 */
                            },
                            onContextMenu: (e)=>{ /* пока не понимаю как это сделать, надо поизучать. TODO: https://codesandbox.io/p/sandbox/rm23kroqyo */ },
                        }
                    }}
                    columns={[
                        {title: "", dataIndex: "type",
                            render: (value, record)=>(
                                <Popover
                                    title={`Cформировать документ по шаблону «${record.code}»`}
                                    content={"Для формирования документа — двойной клик по строке или одинарный по изображению файла"}
                                >
                                    <Button
                                        type={"link"}
                                        onClick={(_)=>{
                                            // console.log("BUILD", app, record)
                                            // setShowTemplate(record)
                                            setApp(Object.assign({}, app, {template: record}))
                                            /*
                                            setPath(""
                                                +"/src/"+encodeURIComponent(record.src)
                                                +"/cat/"+encodeURIComponent(cat || record.category)
                                                +"/tmpl/"+encodeURIComponent(record.code)
                                            )
                                            */
                                        }}
                                    >
                                        <img src={`/${value.slice(0,3)}.png`}  alt={value}/>
                                    </Button>
                                </Popover>
                            ),
                            sorter: (a,b)=> (a.type > b.type ? -1 : 1),
                        },
                        {title: "Код", dataIndex: "code",
                            sorter: (a,b)=> (a.code > b.code ? -1 : 1),
                            ...getColumnSearchProps("code", "Код"),
                        },
                        {title: "Наименование", dataIndex: "title",
                            sorter: (a,b)=> (a.title > b.title ? -1 : 1),
                            ...getColumnSearchProps("title", "Наименование"),
                        },
                        {title: "Описание", dataIndex: "description",
                            sorter: (a,b)=> (a.description > b.description ? -1 : 1),
                            ...getColumnSearchProps("description", "Описание"),
                        },
                        // {
                        //     title: "Автор", dataIndex: "author",
                        //     render: (_, record) => <User avatarOnly={true} defaultText={record.author.name} sub={record.author.sub}/>
                        // },
                        {
                            title: "",
                            key: "actions",
                            render: (_, record) => (
                                <Space direction="vertical">
                                    {
                                        !(record.author && ("sub" in record.author)) ? <></>
                                            : <User avatarOnly={true} defaultText={record.author.name} sub={record.author.sub}/>
                                    }
                                    <Space.Compact>
                                        {[
                                            (record.permissions.indexOf("upd") < 0 ? <></>
                                                    : <Button className={"upd"} size={"small"}
                                                              onClick={(e)=>{
                                                                  record.src = app.storage.code;
                                                                  showFormForUpdTemplate(e, record, (t)=>{ setTemplates(changeItem(templates, t, "code")) })
                                                              }}
                                                    ><EditOutlined/></Button>
                                            ),
                                            (record.permissions.indexOf("del") < 0 ? <></>
                                                    : <Popconfirm
                                                        icon={<DeleteTwoTone/>}
                                                        title={`Удалить шаблон «${record.code}»`}
                                                        description="Вы уверены в необходимости этого?"
                                                        onConfirm={(e)=>{
                                                            record.src = app.storage.code;
                                                            showFormForDelTemplate(e, record, (t)=>{ setTemplates( templates.filter(r=>(r.code!==record.code)) ) })
                                                        }}
                                                        // onCancel={cancel}
                                                        okText="Удалить"
                                                        cancelText="Нет"
                                                    ><Button className={"del"} size={"small"}><DeleteOutlined/></Button></Popconfirm>
                                            ),
                                        ]}
                                    </Space.Compact>
                                </Space>
                            ),
                        },
                    ]}
                    expandable={{
                         expandedRowRender: (record) => {
                             const exp = []
                             if(record.author && ("sub" in record.author) ){
                                 exp.push({
                                     key: "author",
                                     label: "Автор",
                                     children: <User avatarOnly={false} defaultText={record.author.name}
                                                     sub={record.author.sub}/>,
                                 })
                             }
                             if(record.description){
                                 exp.push({
                                     key: "description",
                                     label: "Описание",
                                     children: record.description,
                                 })
                             }
                             if(record.keywords){
                                 exp.push({
                                     key: "keywords",
                                     label: "Ключевые слова",
                                     children: <>{record.keywords.split(";").map(it => <Tag>{it}</Tag>)}</>,
                                 })
                             }
                             return <Descriptions items={exp} />
                         },
                        // rowExpandable: (record) => true // !!record.description,
                    }}
                />
        }

        <FloatButton.Group
            shape="circle"
        >
            <FloatButton
                style={{ insetInlineEnd: 100 }}
                icon={<ExperimentTwoTone />}
                onClick={(e)=>{
                    // setShowTemplate({src: app.storage.code, category: app.category.key, code: "∅"})
                    setApp( Object.assign({}, app, {template: {code: "∅"}}))
                    /*
                    setPath("/"
                        + "src/" + encodeURIComponent(src) + "/"
                        + "cat/" + encodeURIComponent(cat) + "/"
                        + "tmpl/" + encodeURIComponent("∅")
                    )
                    */
                }}
            />

            {!(app && app.storage && app.storage.permissions && app.storage.permissions.indexOf("tmplAdd") >= 0
                && app.category && (app.category.key || app.category.name) !== allCats.key && (app.category.key || app.category.name) !== woCat.key
            ) ? <></>
                : <FloatButton
                    icon={<PlusOutlined />}
                    onClick={(e)=>{
                        showFormForAddTemplate(e, {src: app.storage.code, category: (app.category.key || app.category.name)}, (tmpl)=>{
                            showFormForUpdTemplate(e, tmpl, (t)=>{ setTemplates(changeItem(templates, t, "code")) })
                        })
                    }}
                />
            }

        </FloatButton.Group>

    </>)
}
