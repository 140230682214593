import React, {useContext} from "react";
import {GlobalContext} from "../GlobalContext";
import {Button} from "antd";
import User from "../User";
import {FormForLogin} from "./forms";

export default function LogInOut({
    buttonType = "text"
                                 }) {
    const {
        // config,
        curUserInfo,
        // path, setPath,
        // app, setApp
    } = useContext(GlobalContext)

    return (curUserInfo.sub ? (
                <p className={"userInfo elastic"}>
                    <User user={curUserInfo} />
                    <Button
                        type={buttonType}
                        onClick={(e) => {
                            window["doLogoff"] && window["doLogoff"]()
                        }}
                    >Выход</Button>
                </p>)
                : (<p className={"login elastic"}>
                    <FormForLogin buttonType={buttonType} />
                </p>)
  )
}
