import {linkTo} from "./oth";
import {notification} from "antd";
import {fetchPic} from "../Application/User/funcs";

const apusCode = "apus";

export const saveConfig = (config) => {for(const k in config){localStorage.setItem(`CONFIG_${k}`, config[k])}}
const getConfig = (k) => (localStorage.getItem(`CONFIG_${k}`))

export const gFetch = async (str, params) => {
    if( refreshTokensInProcess ) refreshTokensInProcess.then( _ => ( gFetch(str, params) ) )

    const accToken = accessToken()

    params = params || {}
    params.headers = params.headers || {}

    if (str !== "/config") {// для работы gFetch требуется нормально получение этого конфига!!!
        if (accToken && accToken !== "null") {
            params.headers["Authorization"] = `Bearer ${accToken}`
        }
    }

    if ( accToken ) {
        if(getConfig("AUTH") === "SSO") {
            const currentUser = decodeBase64(accToken.split(".")[1]) || {}
            if (Date.now() >= currentUser.exp * 1000) { // проверть не истек ли срок жизни токена
                try {
                    // console.log(`doRefreshTokens()`)
                    await doRefreshTokens();
                } catch (e) { // если тут что-то пошло не так, то перенаправить пользователя на страницу авторизации
                    console.error(e);
                    return window.location.replace("/login");
                }
            }
        }

        params.headers.Authorization = `Bearer ${accessToken()}`; // добавить токен в headers запроса
    }

    return await window.fetch(linkTo(str), params)
        .then(async it => {
            if( (getConfig("AUTH") === "SSO") && (it.status === 524 || it.status === 401)) {
                return doRefreshTokens().then(async () => (gFetch(str, params)))
            } else {
                if(it.status >= 400){
                    const content = await it.text()
                    const obj = {
                        status: "error",
                        type: it.status,
                        content: content,
                        message: content,
                    }
                    notification.error(obj)

                    if( (getConfig("AUTH") !== "SSO") && (it.status === 524 || it.status === 401) ){
                        notification.error({role: "status", message: "Произошло разлогинивание"})
                        window["doLogoff"] && window["doLogoff"]()
                    }

                    return new Promise((resolve,reject)=>{resolve({json: ()=>(
                            Object.assign({permissions:[]}, obj)
                        )})});
                }else{
                    return it
                }
            }
        })
}

export const accessToken  = ()=>( JSON.parse( atob( localStorage.getItem(apusCode)|| "") || "{}" ).access_token  )
export const refreshToken = ()=>( JSON.parse( atob( localStorage.getItem(apusCode)|| "") || "{}" ).refresh_token )

export const saveToken = (token)=>{
    token = token || {};
    const newApus = Object.assign(JSON.parse( atob( localStorage.getItem( apusCode )  || "") || "{}" ), {access_token: token.access_token, refresh_token: token.refresh_token})

    localStorage.setItem(apusCode, btoa( JSON.stringify(newApus) ))

    let acc = token.access_token
    if(acc) {
        acc = acc.slice(acc.indexOf(".") + 1)
        acc = acc.slice(0, acc.indexOf("."))

        let exp = (new Date()).toUTCString()
        try{
            exp = (new Date((JSON.parse( decodeBase64(acc).replace(/[\u0000-\u0019]+/g,"") ).exp) * 1000)).toUTCString()
        }catch (e) {
            console.error(e)
        }
        document.cookie = `Authorization=${token.access_token}; path=/; expires=${exp}`
    }else{
        document.cookie = `Authorization=; path=/;`
    }
}

let refreshTokensInProcess = false
async function doRefreshTokens() {
    const jwtAccTokenUrl = getConfig("JWT_ACCTOKEN_URL") // useContext(GlobalContext).config["JWT_ACCTOKEN_URL"]
    if(refreshTokensInProcess || !jwtAccTokenUrl){
        return refreshTokensInProcess;
    }

    refreshTokensInProcess = fetch(jwtAccTokenUrl, {
        method: "POST",
        credentials: "include",
        headers: {"Content-Type": "text/plain"},
        body: JSON.stringify({
            refresh_token: refreshToken(),
            grant_type: "refresh_token",
        }),
    })
        .then((it) => {
            return it.json();
        })
        .then((it) => {
            // console.log(`in doRefreshTokens → ${it}`)
            // if(it.errors) appendErrors(it.errors);
            saveToken(it);
            refreshTokensInProcess.then()
            refreshTokensInProcess = false;
            return this;
        });
    return await refreshTokensInProcess
}

export const fetchSessData = function ( setter ) {
    gFetch("/sess")
        .then(it => {
            return it && it.json()
        })
        .then(usr => {
            if(usr) {
                usr.state = true
            }else{
                usr = {state: false}
            }
            const lsKey= `user-${usr.sub}`;
            if(usr.acc) window["acc"] = usr.acc;

            usr.displayName = [usr.family_name, usr.given_name, usr.middle_name].join(" ").trim();
            if(usr.displayName.length === 0) usr.displayName = usr.nickname;
            if( usr.picture && usr.picture.slice(0,4).toLowerCase() === "http" ){ //если url, то получаем по нему картинку
                fetchPic(usr, setter)
            }else {
                sessionStorage.setItem(lsKey, JSON.stringify( usr )) // записать в кэш
                setter(usr)
            }
        })
        .catch( e => {
            console.log(e)
        })
}

export const doEmbeddedLogin = (data, reloader) =>{
    gFetch("/login",
        {
            method: "POST",
            body:JSON.stringify( data )
        })
        .then( it => it.text())
        .then( it => {
            localStorage.setItem(apusCode, it )
            if(reloader){
                reloader()
            }else {
                window.location = window.location // "?"+genUUID()
            }
        })
}

export const decodeBase64 = (s) => {
    if(!s) return null

    var e={},i,b=0,c,x,l=0,a,r='',w=String.fromCharCode,L=s.length;
    var A="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    for(i=0;i<64;i++){e[A.charAt(i)]=i;}
    for(x=0;x<L;x++){
        c=e[s.charAt(x)];b=(b<<6)+c;l+=6;
        while(l>=8){((a=(b>>>(l-=8))&0xff)||(x<(L-2)))&&(r+=w(a));}
    }
    return r;
};
