import {accessToken, gFetch} from "../../core/token";
import {linkTo} from "../../core/oth";
let arrSetterUserInfo // массив сеттеров информации о пользователе

export const fetchUserInfo = (userId, setter, stateSetter)=> {

    if(!userId || userId === "@"){
        stateSetter({type: "ok"})
        setter({})
        return;
    }

    if(arrSetterUserInfo && arrSetterUserInfo[userId] ){
        arrSetterUserInfo[userId].push( setter )
        return;
    }
    if(!arrSetterUserInfo) arrSetterUserInfo = {}
    arrSetterUserInfo[userId] = [setter]

    stateSetter && stateSetter({type: "wait", description: "Подготавливается запрос"}) // setter( [] );
    gFetch(
        linkTo(`/api/users/${userId}`)
    )
        .then(it => {
            stateSetter && stateSetter({type: "wait", description: "Передаётся информация о пользователе"})
            return it.json()
        })
        .then( it => {
            const usr = it // .user || {sub: userId, name: it.errors[0].message}
            if(usr.sub !== userId){ // пришло что-то не то
                arrSetterUserInfo[userId].forEach(s => s(usr))
                arrSetterUserInfo[userId] = null;
                delete arrSetterUserInfo[userId];
                stateSetter && stateSetter({type: "no"})
            }else {
                stateSetter && stateSetter({type: "wait", description: "Подготавливается информация о пользователе"})

                arrSetterUserInfo[userId].forEach(s => s(usr))

                const pic = usr.avatar || usr.picture;
                if (pic && pic.slice(0, 4).toLowerCase() === "http") { //если url, то получаем по нему картинку
                    fetchPic(usr, async (u)=>{
                        for (const s of arrSetterUserInfo[userId]) {
                            await s(u);
                        }
                        arrSetterUserInfo[userId] = null;
                        delete arrSetterUserInfo[userId];
                    }, stateSetter, userId)
                }

                stateSetter && stateSetter({type: "ok"})
            }
        })
        .catch( err => {
            arrSetterUserInfo[userId] = null;
            delete arrSetterUserInfo[userId];

            if(err.name === "AbortError") return;
            stateSetter && stateSetter({type: "error", description: err.message })
        })
}

export function fetchPic(usr, setter, stateSetter, userId){
    setter(usr)
    stateSetter && stateSetter({type: "ok"})
    return;

    stateSetter && stateSetter({type: "pic", description: "Подготавливается картинка пользователя"})
    usr = Object.assign({}, usr)
    userId = userId || usr.sub;
    const pic = usr.avatar || usr.picture;
    const kache = localStorage.getItem(pic);
    if (kache) {

        usr.picture = kache;
        usr.avatar = pic;

        setter(usr);
        if(arrSetterUserInfo && arrSetterUserInfo[userId]) {
            arrSetterUserInfo[userId] = null;
            delete arrSetterUserInfo[userId];
        }
        stateSetter && stateSetter({type: "ok"})
    } else {
        gFetch(pic)
            .then(it => it.blob())
            .then((it) => {
                const fr = new FileReader();
                fr.readAsDataURL(it);
                fr.onloadend = () => {
                    // usr.avatar = "";
                    let rez = fr.result;
                    if (rez.indexOf("data:") === 0) { //если не ошибка
                        if(rez.indexOf("data:application/octet-stream;") === 0){
                            if( 0 === rez.indexOf("data:application/octet-stream;base64,/9j/4AAQ") ){
                                rez = rez.replace("data:application/octet-stream;base64,","data:image/jpeg;base64,")
                            }
                            if( 0 === rez.indexOf("data:application/octet-stream;base64,iVBORw") ){
                                rez = rez.replace("data:application/octet-stream;base64,","data:image/png;base64,")
                            }
                        }
                        if( 0 > rez.indexOf("data:application/json") ) { // если это не json (json - признак ошибки)
                            localStorage.setItem(pic, rez); //кешируем
                        }
                        usr.picture = rez; //сохраняем у пользователя вместо url саму картинку
                        usr.avatar  = pic;
                        setter(usr); // перерисовываем инфо о пользоваетеле
                    } else {
                        setter(usr); // если ошибка, то не кешируем
                    }
                    stateSetter && stateSetter({type: "ok"})
                }
            })
    }
}
