import React, {useContext, useEffect, useRef, useState} from "react";

import {Button, Form, Input, Modal} from "antd";
import Password from "antd/es/input/Password";
import genUUID from "../../core/uuid";
import {doEmbeddedLogin} from "../../core/token";
import {KeyOutlined, UserOutlined} from "@ant-design/icons";
import {GlobalContext} from "../GlobalContext";
import FormItem from "antd/es/form/FormItem";
import {linkTo} from "../../core/oth";


export function FormForLogin({
    buttonType= ""
                      }){
    const {
        config,
        curUserInfo,
        // path, setPath,
        // app, setApp
    } = useContext(GlobalContext)

    const fref = useRef()
    const [sessId, setSessId] = useState( genUUID() )
    const [isOpen, setIsOpen] = useState(false)

    const doLogin = ()=>{
        doEmbeddedLogin({
            session:  fref.current.getFieldValue("session"),
            login:    fref.current.getFieldValue("login"),
            password: fref.current.getFieldValue("password"),
        },)
        setIsOpen(false)
    }

    return <>
        <Modal
            centered
            open={isOpen}
            className={"login"}
            title={ "Авторизация" }
            onCancel={()=>{setIsOpen(false)}}
            afterClose={()=>{ setIsOpen(false) }}
            forceRender={true}
            onOk={doLogin}
            afterOpenChange={()=>{
                if(isOpen) fref.current.focusField("login")
            }}
        >
            <Form
                ref={fref}
                initialValues={{session: sessId}}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                autoComplete="off"
                onFinish={doLogin}
                autoFocus
            >
                <FormItem hidden name={"session"}><Input/></FormItem>

                <FormItem
                    label={"Имя пользователя"}
                    name={"login"}
                    rules={[
                        {
                            required: true,
                            message: "Имя пользователя — обязательно для указания",
                        },
                    ]}
                >
                    <Input
                        placeholder={"Имя пользователя"}
                        prefix={<UserOutlined/>}
                        defaultValue={curUserInfo.nickname}
                        onPressEnter={()=>{ fref.current.focusField("password") }}
                    />
                </FormItem>
                <FormItem
                    label={"Пароль"}
                    name={"password"}
                    rules={[
                        {
                            required: true,
                            message: "Пароль — обязателен для указания",
                        },
                    ]}
                >
                    <Password
                        placeholder={"Пароль"}
                        prefix={<KeyOutlined/>}
                        defaultValue={""}
                        onPressEnter={()=>{ doLogin() }}
                    />
                </FormItem>
            </Form>
        </Modal>
        <Button
            type={buttonType}
            onClick={(e) => {
                if(config["AUTH"] === "DB"){
                    setIsOpen(true)
                }else {
                    window.location = linkTo("/login?redirectUrl=" + window.location)
                }
            }}
        >Авторизация</Button>
    </>
}
