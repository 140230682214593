import "./style.css";

import React, {useEffect, useState} from "react";
import {Avatar, Tooltip} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {fetchPic, fetchUserInfo} from "./funcs";

function UserAvatar({userInfo = {}, displayText = ""}){
    const size="large"
    return (userInfo.avatar || userInfo.avatar_url || userInfo.picture)
        ? <Avatar size={size} shape={"circle"} src={(userInfo.avatar || userInfo.avatar_url ||userInfo.picture)} />
        : (
            displayText
                ? <Avatar size={size} shape={ !!(userInfo.nickname) ? "circle" : "square" }>
                    {
                        userInfo.nickname
                            ? (displayText||"").split(" ").map(w => w[0].toUpperCase()).join("")
                            : displayText
                    }
                </Avatar>
                : <Avatar size={size} shape={"circle"} icon={<UserOutlined/>} />
        )
}

export default function User({
                                 className="",
                                 sub = "",
                                 children = "",
                                 defaultText = "",
                                 avatarOnly = false,
                                 user={},
                                 key = "",
                             }) {
    const [userInfo, setUserInfo] = useState( {} );
    const [displayText, setDisplayText] = useState( defaultText || "" );

    useEffect(() => {
        const ut = (userInfo.name || userInfo.display_name || ( (userInfo.given_name||"")+" "+(userInfo.family_name||"") ).trim() || ( userInfo.nickname || userInfo.login || userInfo.username ) || defaultText )
        if(ut && ut !== displayText) setDisplayText(ut)
    }, [userInfo, defaultText]);

    useEffect(() => {
        const userId = ((user ? user.sub : ( user.nickname ? `@${user.nickname}` : null)) || sub || children)

        if(userId && !("sub" in userInfo)) {
            const lsKey = `user-${userId}`

            if (!(user && user.sub)) {
                if (userId) {
                    const cache = sessionStorage.getItem(lsKey)
                    let jUserInfo = {}
                    if (cache) jUserInfo = JSON.parse(cache)
                    if (jUserInfo && (`${jUserInfo.sub}` === `${userId}` || `@${jUserInfo.nickname}` === userId) && (`${jUserInfo.sub}`!==`${jUserInfo.name}`)) {
                        setUserInfo(jUserInfo)
                    } else {
                        setUserInfo( user || {} )
                        fetchUserInfo(userId
                            , (ui) => {
                                if ((ui.sub === userId  || ( `@${ui.nickname}` === userId )) && ( ui.sub !== ui.name ) ) {
                                    setUserInfo(ui)
                                    sessionStorage.setItem(lsKey, JSON.stringify(ui))
                                }else{
                                    // console.log(userId, user)
                                    // setUserInfo( user )
                                    if ((user.avatar || user.avatar_url || user.picture || "").indexOf("http") === 0) fetchPic(user, setUserInfo, false, userId)
                                    else setUserInfo( Object.assign(user, {sub: ui.sub||user.sub||userId}))
                                }
                            });
                    }
                }
            } else {
                if(user.sub !== user.name) sessionStorage.setItem(lsKey, JSON.stringify(user))
                // user.nickname = `${user.nickname}`
                setUserInfo(user)
                if ((user.avatar || user.avatar || user.picture || "").indexOf("http") === 0) fetchPic(user, setUserInfo, null, userId)
            }
        }
    }, [sub, children, displayText, user, userInfo]);

    return (
        <span className={"user " + className} data-sub={userInfo.sub}  data-nickname={userInfo.nickname} key={key}>
            { avatarOnly
                ? <Tooltip title={displayText}>
                    <p className={"pForAvatarOnly"}>{/* АЛЁНА, ЭТО - КОСТЫЛЬ! */}</p>
                    <UserAvatar userInfo={userInfo} displayText={displayText}/>
                </Tooltip>
                : <>
                    {/* <span className="user-nickname">{userInfo.nickname || userInfo.login || userInfo.username}</span> */}
                    <UserAvatar userInfo={userInfo} displayText={displayText}/>
                    {displayText ? <span className="user-name">{displayText}</span> : <></>}
                </>
            }
        </span>
    )
}
