import './style.css';

import React from "react";

import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';

import LinkMD from "./LinkMD";
import ImageMD from "./ImageMD";
import remarkIssPlugin from "./remarkIssPlugin";

function childrenToID(children){
    return ((typeof children == "string") ? children : (
        (children.length === 0) ? children[0] : JSON.stringify(children)
    )).replaceAll(/[^a-z0-9а-я]/ig, "_").replaceAll(/(^__)|(__$)/g, "")
}

export default function MD({
    setPath = (p)=>{},
    style = {},
    children = <></>,
}) {
    return (
        <div
            className={"md"}
            style={style}
        >
            <Markdown
                remarkPlugins={[remarkGfm, remarkIssPlugin]}
                components={{
                    a:   (props)=>(LinkMD( Object.assign({setPath: setPath}, props))),
                    img: (props)=>(ImageMD( Object.assign({setPath: setPath}, props))),

                    h1: (props)=>(<h1 id={childrenToID(props.children)}>{props.children}</h1>),
                    h2: (props)=>(<h2 id={childrenToID(props.children)}>{props.children}</h2>),
                    h3: (props)=>(<h3 id={childrenToID(props.children)}>{props.children}</h3>),
                }}
            >{children}</Markdown>
        </div>
    );
}
